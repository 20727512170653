import { EmployerNotification } from "@/dto/notification/notification";
import { useFetchStateStore } from "@/store/fetch-state";

export const useNotificationStore = defineStore("notification", () => {
  const notifications = ref<EmployerNotification[]>([]);
  const fetchStateStore = useFetchStateStore();

  const getNotifications = async () => {
    await fetchStateStore.fetchOnce("notifications", async () => {
      const user = useSupabaseUser();
      notifications.value = await $api<EmployerNotification[]>(`${API_ROUTES.notification}/${user.value?.id}`);
    });
  };

  const markAllAsRead = async () => {
    const user = useSupabaseUser();
    if (notifications.value.some((ntf) => !ntf.read))
      await $api<EmployerNotification[]>(API_ROUTES.notification, {
        method: "post",
        body: { employerId: user.value?.id },
      });
  };

  const deleteAll = async () => {
    notifications.value = [];
    const user = useSupabaseUser();
    await $api<EmployerNotification[]>(`${API_ROUTES.notification}/${user.value?.id}`, {
      method: "delete",
    });
  };

  const deleteNotification = async (id: number) => {
    const index = notifications.value.findIndex((n: EmployerNotification) => n.id === id);
    if (index !== -1) notifications.value.splice(index, 1);

    await $api<EmployerNotification>(`${API_ROUTES.notification}/${id}`, {
      method: "delete",
    });
  };

  const addNotificationToStore = (notification: EmployerNotification) => {
    if (!notification) throw createError({ statusMessage: ERROR_MESSAGES.notification_not_found });
    const index = notifications.value.findIndex((n: EmployerNotification) => n.id === notification.id);
    index !== -1 ? (notifications.value[index] = notification) : notifications.value.unshift(notification);
  };

  return { notifications, getNotifications, deleteNotification, deleteAll, addNotificationToStore, markAllAsRead };
});
